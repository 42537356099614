import { authorizationService, type IAuthorizationHelper, type IAuthService } from '@vdi/auth-service'
import { permissionRoutes } from './utils/routes/permissionRoutes'

export let authorizationHelper: IAuthorizationHelper
export let authService: IAuthService

export async function initializeServices(bootstrapUtilities, initApp) {
  const services = await authorizationService.init()
  authorizationHelper = services.authorizationHelper
  authService = services.authService

  permissionRoutes()
  bootstrapUtilities(authService)
  initApp(authService)
}
